html,
body,
#root {
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
  background-color: #efefef;
}

.App-logo {
  height: 35px;
  align-self: center;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ff4c00;
  height: 50px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.service {
  padding: 5px;
  margin: 15px;
}

.label {
  display: flex;
  flex-direction: row;
  margin: 10px 5px;
}

.label-text {
  width: 35%;
  text-align: left;
}

.arrival-button {
  width: calc(100% - 40px);
  position: fixed;
  bottom: 0;
  margin: 20px 0px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pin {
  height: 150px;
  width: 100px;
  filter: opacity(30%);
  background: url('./assets/pin.svg');
  background-repeat: no-repeat;
}

.mb-25 {
  margin-bottom: 25%;
}
